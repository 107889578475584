import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Modal from "../../../app/components/modals/Modal";
import useStyles from "./styles";
import ShowEmployeesSection from "./components/ShowEmployeesSection";
import ShowBenefitsSection from "./components/ShowBenefitsSection";
import ShowConfigSection from "./components/ShowConfigSection";

export default function BenefitBagDetail({
  isLoading,
  initialDate,
  employees,
  activeBenefits,
  allowedBenefits,
  adjustments,
  alias,
  renewAtPeriodEnd,
}) {
  const {
    containerModal,
    currentPeriodContainer,
    section,
    sectionTitle,
  } = useStyles();

  const {
    periodicity,
    amount,
    accumulable,
    deliverUnusedExemptToPurses,
  } = adjustments;
  const finalCurrentDate = moment(initialDate)
    .add(1, periodicity === 12 ? "y" : "M")
    .format("YYYY-MM-DD");

  return (
    <Modal title="Benefit Bag Detail">
      <Grid container className={containerModal}>
        {!isLoading ? (
          <>
            <Grid container justify="center">
              <Grid item className={currentPeriodContainer}>
                <Typography>Current</Typography>
                <Typography variant="body2">{`${initialDate} / ${finalCurrentDate}`}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" className={section}>
              <Grid container>
                <Typography className={sectionTitle}>
                  EMPLOYEES INCLUDED
                </Typography>
              </Grid>
              <ShowEmployeesSection employees={employees} />
            </Grid>

            <Grid container direction="column" className={section}>
              <Grid container>
                <Typography className={sectionTitle}>
                  BENEFITS INCLUDED
                </Typography>
              </Grid>

              <ShowBenefitsSection
                activeBenefits={activeBenefits}
                allowedBenefits={allowedBenefits}
              />
            </Grid>

            <Grid container direction="column" className={section}>
              <Grid container>
                <Typography className={sectionTitle}>CONFIGURATION</Typography>
              </Grid>

              <ShowConfigSection
                amount={amount}
                periodicity={periodicity}
                accumulable={accumulable}
                deliverUnusedExemptToPurses={deliverUnusedExemptToPurses}
                renewAtPeriodEnd={renewAtPeriodEnd}
              />
            </Grid>

            <Grid container direction="column" className={section}>
              <Typography className={sectionTitle}>ALIAS</Typography>
              <Typography>{alias}</Typography>
            </Grid>
          </>
        ) : (
          <Grid container justify="center">
            <CircularProgress size={32} />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}

BenefitBagDetail.propTypes = {
  isLoading: PropTypes.bool,
  employees: PropTypes.array,
  initialDate: PropTypes.string,
  activeBenefits: PropTypes.array,
  allowedBenefits: PropTypes.array,
  adjustments: PropTypes.object,
  alias: PropTypes.string,
  renewAtPeriodEnd: PropTypes.bool,
};

BenefitBagDetail.defaultProps = {
  isLoading: false,
  initialDate: "2021-01-01",
  employees: [],
  activeBenefits: [],
  allowedBenefits: [],
  adjustments: {},
  alias: "",
  renewAtPeriodEnd: true,
};
