import featureFlagsDictionary from "./feature-flags-dictionary";

const sdkConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_APIKEY,
    key: "key",
  },
  features: {
    [featureFlagsDictionary.COB_1796]: "on",
    [featureFlagsDictionary.BT_12]: "on",
  },
};

export default sdkConfig;
