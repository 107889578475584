import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import bHistory from "history/createBrowserHistory";
import queryString from "query-string";

import LifeInsurancePolicy from "app/domain/LifeInsurancePolicy";
import auth0Client from "app/auth/Auth";

import renderCensusProgressPage from "infrastructure/renders/CensusProgressPage/render-census-progress-page";
import renderCensusUploadPage from "infrastructure/renders/CensusUploadPage/render-census-upload-page";
import renderAddLifeInsurancePage from "infrastructure/renders/AddLifeInsurancePage/render-add-life-insurance-page";

import { Website } from "usecases/Website";
import { displayCompaniesPage } from "usecases/companies/display-companies-page";
import { displayEmployeesPage } from "usecases/employees/display-employees-page";
import { displayNurseriesPage } from "usecases/nurseries/display-nurseries-page";
import { displayMovementsPage } from "usecases/movements/display-movements-page";
import { displayTransactionsPage } from "usecases/transactions/display-transactions-page";
import { displayFinancePage } from "usecases/finance/display-finance-page";
import reviewLifeInsuranceRequests from "usecases/lifeInsurance/reviewLifeInsuranceRequests";
import displayCountryTaxRulesPage from "usecases/countries/display-country-tax-rules-page";

import CorporationsPage from "ui/pages/corporations";
import { editRentingBenefitConfig } from "usecases/companies/edit-renting-benefit-config";
import RetirementInsuranceConfigPage from "ui/pages/RetirementInsuranceConfigPage";
import PensionPlanConfigPage from "ui/pages/PensionPlanConfigPage";
import Contributions from "ui/pages/contributions";
import { ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import PrivateRoute from "./PrivateRoute";
import CompanyDetailsPage from "../../ui/pages/CompanyDetailsPage";
import BenefitModelDetailsPage from "../../ui/pages/BenefitModelDetailsPage";
import HealthInsuranceOverview from "../../v2/ui/contexts/healthInsurance/pages/HealthInsuranceOverview";
import EditBenefitModelPage from "../../ui/pages/EditBenefitModelPage";
import {
  generateBenefitModelDetailLink,
  generateBenefitModelEmployeesLink,
  generateEditBenefitModelLink,
} from "../../infrastructure/links";
import BenefitModelEmployeesPage from "../../ui/pages/BenefitModelEmployeesPage";
import { NavMenu } from "../../v2/ui/components/NavMenu";
import { QueryProvider } from "../../v2/ui/providers/QueryProvider";

const history = bHistory();

export function RouteWrapper({ children }) {
  return (
    <QueryProvider>
      <ToastContainer
        id="toast-container"
        position="top-right"
        closeOnClick
        pauseOnVisibilityChange
        pauseOnHover
      />
      <NavMenu />
      {children}
    </QueryProvider>
  );
}
RouteWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute
          history={history}
          path="/companies/:companyId/health-insurance-overview"
          exact
          component={HealthInsuranceOverview}
        />
        <PrivateRoute
          history={history}
          path="/companies/:companyId"
          exact
          component={() => (
            <RouteWrapper>
              <CompanyDetailsPage />
            </RouteWrapper>
          )}
        />

        <PrivateRoute
          history={history}
          path={generateBenefitModelDetailLink({
            benefitModelId: ":benefitModelId",
            companyId: ":companyId",
          })}
          exact
          component={() => (
            <RouteWrapper>
              <BenefitModelDetailsPage />
            </RouteWrapper>
          )}
        />

        <PrivateRoute
          history={history}
          path={generateEditBenefitModelLink({
            benefitModelId: ":benefitModelId",
            companyId: ":companyId",
          })}
          exact
          component={() => (
            <RouteWrapper>
              <EditBenefitModelPage />
            </RouteWrapper>
          )}
        />

        <PrivateRoute
          history={history}
          path={generateBenefitModelEmployeesLink({
            benefitModelId: ":benefitModelId",
            companyId: ":companyId",
          })}
          exact
          component={() => (
            <RouteWrapper>
              <BenefitModelEmployeesPage />
            </RouteWrapper>
          )}
        />

        <PrivateRoute
          history={history}
          path="/"
          exact
          render={() => <Website onMount={displayEmployeesPage} />}
        />

        <PrivateRoute
          path="/corporations"
          render={() => (
            <Website>
              <CorporationsPage />
            </Website>
          )}
        />

        <PrivateRoute
          history={history}
          path="/companies"
          exact
          render={() => <Website onMount={displayCompaniesPage} />}
        />

        <PrivateRoute
          history={history}
          path="/employees"
          exact
          render={() => <Website onMount={displayEmployeesPage} />}
        />

        <PrivateRoute
          history={history}
          path="/nurseries"
          exact
          render={() => <Website onMount={displayNurseriesPage} />}
        />

        <PrivateRoute
          history={history}
          path="/movements"
          exact
          render={() => <Website onMount={displayMovementsPage} />}
        />

        <PrivateRoute
          history={history}
          path="/contributions"
          exact
          render={() => (
            <Website>
              <Contributions />
            </Website>
          )}
        />

        <PrivateRoute
          history={history}
          path="/life-insurance-requests"
          exact
          render={() => (
            <Website onMount={() => reviewLifeInsuranceRequests()} />
          )}
        />

        <PrivateRoute
          history={history}
          path="/transactions"
          exact
          render={() => <Website onMount={displayTransactionsPage} />}
        />
        <PrivateRoute
          history={history}
          path="/finance"
          exact
          render={() => <Website onMount={displayFinancePage} />}
        />

        <PrivateRoute
          history={history}
          path="/upload-census-file"
          render={() => <Website onMount={() => renderCensusUploadPage({})} />}
        />

        <PrivateRoute
          history={history}
          path="/benefit-config/renting-benefit"
          render={() => (
            <Website onMount={() => editRentingBenefitConfig({})} />
          )}
        />

        <PrivateRoute
          path="/companies/benefit-config/retirement-insurance"
          render={() => (
            <Website>
              <RetirementInsuranceConfigPage />
            </Website>
          )}
        />

        <PrivateRoute
          path="/companies/benefit-config/pension-plan-benefit"
          render={() => (
            <Website>
              <PensionPlanConfigPage />
            </Website>
          )}
        />

        <PrivateRoute
          history={history}
          path="/census-status"
          render={() => (
            <Website onMount={() => renderCensusProgressPage({})} />
          )}
        />

        <PrivateRoute
          history={history}
          path="/add-life-insurance"
          render={() => (
            <Website
              onMount={() =>
                renderAddLifeInsurancePage({
                  lifeInsurancePolicy: new LifeInsurancePolicy({
                    companyId: queryString.parse(window.location.search)
                      .company,
                  }),
                })
              }
            />
          )}
        />

        <PrivateRoute
          history={history}
          path="/country-tax-rules"
          render={() => (
            <Website onMount={() => displayCountryTaxRulesPage({})} />
          )}
        />

        <Route path="/callback" render={() => <Redirect to="/" />} />
        <Route
          path="/logout"
          render={() => {
            auth0Client.logOut();
            return <div />;
          }}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
