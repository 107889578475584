import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";
import Table from "../../../app/components/Table";
import CobeeButton from "../../../app/components/CobeeButton";
import useStyles from "./styles";
import { displaySolvedConfirmationAboveAuthorizedModal } from "../../../usecases/transactions/display-solved-confirmation-amount-above-authorized-modal";

export default function ConfirmationAboveAuthorizedTransactions({ tableData }) {
  const { title } = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [processorTransactionId, setProcessorTransactionId] = useState("");
  const [currency, setCurrency] = useState("");

  return (
    <main className="container-fluid">
      <Typography variant="h5" className={title}>
        Transactions with confirmation amount above authorized amount
      </Typography>
      <div className="form-group row">
        <div className="col-sm-4 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Company name"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className="col-sm-4 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Processor transaction id"
            onChange={(e) => setProcessorTransactionId(e.target.value)}
          />
        </div>
        <div className="col-sm-3 mb-2">
          <select
            className="form-control"
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option selected value="">
              All Countries
            </option>
            <option value="MXN">Mexico</option>
            <option value="EUR">ES & PT</option>
          </select>
        </div>
      </div>
      {tableData.length === 0 ? (
        <Typography variant="body1" className="text-center">
          There are currently no transactions that confirmation are above the
          authorization
        </Typography>
      ) : (
        <Table
          columns={[
            { label: "Processor transaction id", id: "transactionId" },
            { label: "Concept", id: "concept" },
            { label: "Company", id: "company" },
            { label: "Auth", id: "intendedAmount" },
            { label: "Confirmation", id: "amount" },
            { label: "Diff", id: "diffAmount" },
            { label: "Date", id: "date" },
            { label: "Card Id", id: "cardId" },
            { label: "Employee state", id: "employeeState" },
            { label: "Company state", id: "companyState" },
            { label: "Actions", id: "actions" },
          ]}
          data={tableData
            .filter(
              (td) =>
                !companyName ||
                td.company?.name?.match(new RegExp(companyName, "gi"))
            )
            .filter(
              (td) =>
                !processorTransactionId ||
                td.transactionId?.match(
                  new RegExp(`^${processorTransactionId}`, "i")
                )
            )
            .filter((td) => !currency || td.amount?.currency === currency)
            .map(
              ({
                amount,
                company,
                intendedAmount,
                initialAccountBalance,
                finalAccountBalance,
                localIsoTime,
                unknownProcessorNotificationId,
                ...rest
              }) => ({
                amount: MonetaryAmount.ofCurrency(amount).prettifyMoney({}),
                company: company?.name,
                intendedAmount: MonetaryAmount.ofCurrency(
                  intendedAmount
                ).prettifyMoney({}),
                diffAmount: MonetaryAmount.ofCurrency(intendedAmount)
                  .subtract(MonetaryAmount.ofCurrency(amount))
                  .prettifyMoney({}),
                initial: MonetaryAmount.ofCurrency(
                  initialAccountBalance
                ).prettifyMoney({}),
                final: MonetaryAmount.ofCurrency(
                  finalAccountBalance
                ).prettifyMoney({}),
                date: moment(localIsoTime).format("DD-MM-YYYY HH:mm"),
                actions: (
                  <CobeeButton
                    onClick={() =>
                      displaySolvedConfirmationAboveAuthorizedModal({
                        id: unknownProcessorNotificationId,
                        intendedAmount: MonetaryAmount.ofCurrency(
                          intendedAmount
                        ).prettifyMoney({}),
                        confirmedAmount: MonetaryAmount.ofCurrency(
                          amount
                        ).prettifyMoney({}),
                      })
                    }
                  >
                    Solved
                  </CobeeButton>
                ),
                ...rest,
              })
            )}
        />
      )}
    </main>
  );
}

ConfirmationAboveAuthorizedTransactions.defaultProps = {
  tableData: [],
};

ConfirmationAboveAuthorizedTransactions.propTypes = {
  tableData: PropTypes.array,
};
