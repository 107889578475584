import React from "react";
import ReactDOM from "react-dom";

import HexModal from "../../app/components/modals/HexModal";
import { executeManualTransaction } from "../../infrastructure/finance-repository";
import { showErrorMessage } from "../commons/show-error-message";

import { hideModal } from "../commons/hide-modal";
import { displayFinancePage } from "./display-finance-page";
import { showSuccessMessage } from "../commons/show-success-message";
import ConfirmGenerateManualTransactionModal from "../../app/components/modals/ConfirmGenerateManualTransactionModal";

const renderConfirmManualTransactionModal = ({ disabled, ...rest }) => {
  ReactDOM.render(
    <HexModal disabled={disabled}>
      <ConfirmGenerateManualTransactionModal {...rest} />
    </HexModal>,
    document.getElementById("modal-container")
  );
};

export default async function generateManualTransaction({
  employee,
  category,
  type,
  currency,
  searchValues,
}) {
  renderConfirmManualTransactionModal({ isLoading: true });
  const action = () =>
    renderConfirmManualTransactionModal({
      isLoading: false,
      category,
      type,
      employee,
      currency,
      behaviours: searchValues.behaviours,
      callback: async ({ values }) => {
        renderConfirmManualTransactionModal({
          isLoading: true,
          category,
          type,
          employee,
          currency,
          behaviours: searchValues.behaviours,
          defaultValues: values,
        });
        const behaviours = searchValues.behaviours.filter(
          (behaviour) => behaviour.amount?.amountInCents
        );

        try {
          await executeManualTransaction({
            employeeId: employee.id,
            payload: {
              category,
              type,
              date: searchValues.date,
              transactionId: searchValues.transactionId,
              concept: searchValues.description,
              payrollId: searchValues.payrollId,
              authorizationId: searchValues.authorizationId,
              processorTransactionId: searchValues.processorTransactionId,
              recurrentPaymentId: searchValues.recurrentPaymentId,
              behaviours,
              ...values,
            },
          });

          hideModal();
          await displayFinancePage({ searchValues });
          showSuccessMessage("Transaction created successfully");
        } catch (err) {
          action();
          showErrorMessage(
            err?.response?.data?.message || "An error has occurred"
          );
        }
      },
    });

  action();
}
