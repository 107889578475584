import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MUI } from "@cobee/cobee-ui";
import { Search as SearchIcon } from "@material-ui/icons";
import { saveEmployee } from "../../save-employee";
import BalanceDetail from "../BalanceDetail";
import EmployeeForm from "../EmployeeForm";
import FamilyMembers from "../FamilyMembers";
import CardTable from "../CardTable";
import RecurrentPaymentTable from "../RecurrentPayments/Table";
import CobeeButton from "../../../../app/components/CobeeButton";
import { styles } from "./styles";
import { downloadCardProcessorLogs } from "../../../card-processor-logs";
import ErrorLogTable from "../ErrorLogTable";
import { Address } from "../../../../app/domain/address";
import { generateBenefitModelDetailLink } from "../../../../infrastructure/links";
import { getAllRecurrentPaymentTransactionCategories } from "../../../../app/domain/transaction-category";
import RetentionsDetail from "../RetentionsDetail";

const recurrentPaymentsV2Categories = getAllRecurrentPaymentTransactionCategories();

function capitalize(text) {
  return text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

function formatCategoryForTabs(category) {
  return capitalize(category.replace("-benefit", "").replace("-", " "));
}

class EmployeeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      employee: null,
      card: null,
      isFetchCard: false,
      isSendingEmail: false,
      isSavingEmployee: false,
      selectedRecurrentPaymentCategory: recurrentPaymentsV2Categories[0],
    };
  }

  componentDidMount() {
    const { employee, cards } = this.props;
    if (employee.id !== undefined) {
      const card = cards[0];
      const { deliveryAddress, homeAddress } = employee;

      this.setState({
        id: employee.id,
        employee: {
          ...employee,
          deliveryAddress: new Address(deliveryAddress),
          homeAddress: new Address(homeAddress),
        },
        card,
      });
    }
  }

  componentDidUpdate() {
    const { employee, cards } = this.props;
    const { id } = this.state;
    if (employee.id !== id) {
      const card = cards[0];
      this.setState({
        id: employee.id,
        employee,
        card,
        isFetchCard: false,
        isSendingEmail: false,
      });
    }
  }

  handlerSave = async () => {
    const { employee } = this.state;
    this.setState({ isSavingEmployee: true });
    await saveEmployee({
      ...employee,
      deliveryAddress: {
        province: employee.deliveryAddress.province,
        locality: employee.deliveryAddress.locality,
        streetName: employee.deliveryAddress.streetName,
        streetNumber: employee.deliveryAddress.streetNumber,
        details: employee.deliveryAddress.details,
        postalCode: employee.deliveryAddress.postalCode,
      },
      homeAddress: {
        province: employee.homeAddress.province,
        locality: employee.homeAddress.locality,
        streetName: employee.homeAddress.streetName,
        streetNumber: employee.homeAddress.streetNumber,
        details: employee.homeAddress.details,
        postalCode: employee.homeAddress.postalCode,
      },
    });
    this.setState({ isSavingEmployee: false });
  };

  changeAddress = ({ type, property, value }) => {
    const { employee } = this.state;
    this.setState({
      employee: {
        ...employee,
        [type]: {
          ...employee[type],
          [property]: value,
        },
      },
    });
  };

  onRecurrentPaymentCategoryTabClick(category) {
    this.setState({
      selectedRecurrentPaymentCategory: category,
    });
  }

  render() {
    const {
      limits,
      benefitBagsAvailable,
      company,
      familyMembers,
      cards,
      fundsByCategory,
      accumulatedFlexByCategory,
      accumulationDeliveries,
      allowanceDeliveries,
      accumulatedFlexAllowed,
      lockedFlex,
      recurrentPaymentsV2,
      benefitModel,
      retentions,
    } = this.props;
    const {
      employee,
      card,
      email,
      isSendingEmail,
      isFetchCard,
      isSavingEmployee,
      selectedRecurrentPaymentCategory,
    } = this.state;
    const {
      classes: { tableActionButtonStyle },
    } = this.props;

    if (employee && employee.id) {
      return (
        <div>
          <EmployeeForm
            employee={employee}
            card={card}
            handlerInputChange={({ property, value }) =>
              this.setState({
                employee: {
                  ...employee,
                  [property]: value,
                },
              })
            }
            handleChangeAddress={this.changeAddress}
            isFetch={isSendingEmail || isFetchCard}
          />
          <div className="mt-2 mb-5">
            <div className="card-body">
              <div className="row float-right">
                <button
                  className="btn btn-primary"
                  onClick={this.handlerSave}
                  disabled={isSavingEmployee}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-body">
              <p className="card-text">Company:</p>
              <hr />
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">ID</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="CompanyId"
                    value={company.id}
                  />
                </div>

                <label className="col-sm-2 col-form-label">Name</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="State"
                    value={company.companyName}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-body">
              <p className="card-text">Benefit Model:</p>
              <hr />
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">Name</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="CompanyId"
                    value={
                      benefitModel?.name ??
                      "Employee Group eliminado o no encontrado"
                    }
                  />
                </div>

                {benefitModel && (
                  <div className="col-sm-4">
                    <MUI.Link
                      href={generateBenefitModelDetailLink({
                        benefitModelId: employee.modelId,
                        companyId: employee.companyId,
                      })}
                      sx={{ pl: 1 }}
                    >
                      <MUI.Button
                        variant="contained"
                        color="primary"
                        startIcon={<SearchIcon />}
                      >
                        Detail
                      </MUI.Button>
                    </MUI.Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-body">
              <p className="card-text">Cards</p>
              <hr />
              {cards.length > 0 ? (
                <CardTable cards={cards} />
              ) : (
                <Typography variant="body1" align="center">
                  There are not created cards
                </Typography>
              )}
              <Grid
                className={tableActionButtonStyle}
                item
                container
                justify="flex-end"
              >
                <CobeeButton
                  variant="primary"
                  onClick={() =>
                    downloadCardProcessorLogs({
                      employeeId: employee.id,
                    })
                  }
                >
                  Download logs
                </CobeeButton>
              </Grid>
            </div>
          </div>
          {retentions && <RetentionsDetail retentions={retentions} />}

          <BalanceDetail
            employeeId={employee.id}
            isFetch={isFetchCard}
            limits={limits}
            benefitBagsAvailable={benefitBagsAvailable}
            accumulatedFlexByCategory={accumulatedFlexByCategory}
            fundsByCategory={fundsByCategory}
            accumulationDeliveries={accumulationDeliveries}
            allowanceDeliveries={allowanceDeliveries}
            lockedFlex={lockedFlex}
            accumulatedFlexAllowed={accumulatedFlexAllowed || {}}
          />

          <FamilyMembers
            employeeId={employee.id}
            homeAddress={employee.homeAddress}
          />

          <div className="card mb-3">
            <div className="card-header">
              <p className="card-text">Recurrent Payments</p>
              <hr />
              <ul className="nav nav-tabs card-header-tabs">
                {recurrentPaymentsV2Categories.map((category) => (
                  <li className="nav-item" key={v4()}>
                    <button
                      className={
                        selectedRecurrentPaymentCategory === category
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() =>
                        this.onRecurrentPaymentCategoryTabClick(category)
                      }
                    >
                      {formatCategoryForTabs(category)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <RecurrentPaymentTable
              employee={employee}
              category={selectedRecurrentPaymentCategory}
              recurrentPayments={recurrentPaymentsV2.filter(
                (rp) => rp.category === selectedRecurrentPaymentCategory
              )}
            />
          </div>

          <div className="card mb-3">
            <div className="card-header">
              <p className="card-text">Error Logs</p>
              <hr />
            </div>
            <ErrorLogTable employeeId={employee.id} />
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <p className="card-text">Employees not found.</p>
        </div>
      </div>
    );
  }
}

EmployeeDetail.defaultProps = {};
EmployeeDetail.propTypes = {
  employee: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired,
};

export default withStyles(styles)(EmployeeDetail);
