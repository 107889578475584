import { MonetaryAmount } from "app/domain/monetary-amount";
import { cachedT } from "../../adaptExcelPayload";

export const parseSavingsBenefitsFailures = (data) => {
  return data.map(
    ({
      data: {
        employeeLegalId,
        previousCompanyContribution,
        previousEmployeeContribution,
        acceptedDate,
        policyNumber,
      },
      ...rest
    }) => {
      return {
        data: {
          [cachedT["DNI Empleado"]]: employeeLegalId,
          [cachedT[
            "Aportado por la empresa este año (€)"
          ]]: MonetaryAmount.ofCurrency(
            previousCompanyContribution
          ).toUnitary(),
          [cachedT[
            "Aportado por el empleado este año (€)"
          ]]: MonetaryAmount.ofCurrency(
            previousEmployeeContribution
          ).toUnitary(),
          [cachedT[
            "Fecha de efecto en el servicio (aaaa-mm-dd)"
          ]]: acceptedDate,
          [cachedT["Número de póliza"]]: policyNumber,
        },
        ...rest,
      };
    }
  );
};
