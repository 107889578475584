import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Table as MuiTable } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { v4 } from "uuid";
import useStyles from "./styles";
import CustomPagination from "./CustomPagination";

function EnhancedTableHead({
  onSelectAllClick,
  numSelected,
  rowCount,
  columns,
  withCheckbox,
  headerClasses,
}) {
  return (
    <TableHead className={headerClasses}>
      <TableRow>
        {withCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={`${headCell.id}-cell-head`}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Table({
  columns,
  data,
  pageNumber,
  pageSize,
  onChange,
  onChangeRowsPerPage,
  totalRows,
  textEllipsisLimit,
  testId,
  withCheckbox,
  setSelectedItems,
  defaultSelected,
  forcePagination,
}) {
  const {
    root,
    container,
    header,
    cell,
    tableRow,
    paddingRightForCell,
  } = useStyles({
    length: data.length,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageSize ?? 10);
  const [selected, setSelected] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    if (onChange) {
      onChange(newPage + 1);
      if (forcePagination) {
        setPage(newPage);
      }
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(event.target.value);
    } else {
      setRowsPerPage(event.target.value);
      setPage(0);
    }
  };

  const shouldSlice = (values) => {
    if (
      (totalRows > 0 &&
        pageSize > 0 &&
        pageNumber >= 0 &&
        onChange &&
        onChangeRowsPerPage) ||
      forcePagination
    ) {
      return values;
    }
    return values.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const textEllipsis = (text) => {
    if (
      textEllipsisLimit &&
      typeof text === "string" &&
      text.length > textEllipsisLimit - 3
    ) {
      return `${text.slice(0, textEllipsisLimit)}...`;
    }
    return text;
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map(({ id }) => id);
      setSelected(newSelected);
      setSelectedItems(newSelected);
      return;
    }
    setSelected([]);
    setSelectedItems([]);
  };

  const handleCheckbox = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectedItems(newSelected);
  };

  const isSelected = (name) => {
    if (withCheckbox) {
      return (
        selected.indexOf(name) !== -1 || defaultSelected.indexOf(name) !== -1
      );
    }
    return false;
  };

  return (
    <div className={root}>
      <input data-testid={testId} type="hidden" readOnly value={data.length} />
      <TableContainer className={container}>
        <MuiTable stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            withCheckbox={withCheckbox}
            columns={columns}
            numSelected={defaultSelected.length || selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data.length}
            headerClasses={header}
          />
          <TableBody>
            {shouldSlice(data).map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={v4()}
                  className={tableRow}
                  selected={row.checkbox?.props.checked || isItemSelected}
                >
                  {withCheckbox && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(event) => handleCheckbox(event, row.id)}
                      />
                    </TableCell>
                  )}
                  {columns.map(({ id, align, format }) => {
                    const value = row[id];
                    return (
                      <TableCell
                        key={v4()}
                        align={align}
                        className={`${cell} ${
                          align === "right" && paddingRightForCell
                        }`}
                      >
                        {textEllipsis(
                          format && typeof value === "number"
                            ? format(value)
                            : value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {data.length > 0 && (
        <TablePagination
          rowsPerPageOptions={pageSize ? [pageSize] : [10, 25, 50]}
          component="div"
          count={totalRows || data.length - 1}
          rowsPerPage={pageSize !== undefined ? pageSize : rowsPerPage}
          page={pageNumber !== undefined ? pageNumber : page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={CustomPagination}
          labelRowsPerPage="Rows per page"
          labelDisplayedRows={() => {}}
        />
      )}
    </div>
  );
}

Table.defaultProps = {
  data: [],
  pageNumber: undefined,
  pageSize: undefined,
  onChange: undefined,
  onChangeRowsPerPage: undefined,
  totalRows: undefined,
  textEllipsisLimit: undefined,
  testId: "",
  withCheckbox: false,
  setSelectedItems: () => {},
  defaultSelected: [],
  forcePagination: false,
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  onChange: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  totalRows: PropTypes.number,
  textEllipsisLimit: PropTypes.number,
  testId: PropTypes.string,
  withCheckbox: PropTypes.bool,
  setSelectedItems: PropTypes.func,
  defaultSelected: PropTypes.array,
  forcePagination: PropTypes.bool,
};
