import React, { Component } from "react";
import {
  createWithdrawalRequest,
  findEmployeesByEmail,
} from "../../../infrastructure/employee-repository";
import { showErrorMessage } from "../../commons/show-error-message";
import { showSuccessMessage } from "../../commons/show-success-message";

class WithdrawalSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      numMp: "",
      behaviour: "",
      category: "",
      description: "",
      employeeId: "",
      employeeIds: [],
      employeeCountryIsoCode: "ES",
      password: "",
      amount: 0,
    };
  }

  async handleSearch() {
    const { email, numMp } = this.state;
    try {
      const data = await findEmployeesByEmail(email, numMp);
      if (data.length > 1) {
        showErrorMessage("Duplicated email -> Select correct id");
      }
      const ids = [];
      data.map(({ employee }) => {
        const { id } = employee;
        return ids.push(id);
      });

      this.setState({
        employeeIds: ids,
        employeeId: ids.length === 1 ? ids[0] : "",
        employeeCountryIsoCode:
          ids.length === 1
            ? data[0].employee.regionalInfo.countryISO2Code
            : "ES",
      });
    } catch (error) {
      showErrorMessage("Employee not found");
    }
  }

  async handleWithdrawal() {
    const {
      employeeId,
      amount,
      password,
      behaviour,
      category,
      description,
      employeeCountryIsoCode,
    } = this.state;
    const amountInCents = +(amount * 100).toFixed(0);
    const currency = employeeCountryIsoCode === "MX" ? "MXN" : "EUR";

    const res = await createWithdrawalRequest(employeeId, {
      password,
      behaviour,
      category,
      amount: {
        amountInCents,
        currency,
      },
      concept: description,
    });
    if (res?.data?.response?.success) {
      showSuccessMessage("success withdrawal");
    } else {
      showErrorMessage("Wrong. Some Data are not correct");
    }
  }

  render() {
    const {
      password,
      employeeIds,
      employeeId,
      behaviour,
      category,
      amount,
      description,
      email,
      numMp,
    } = this.state;
    const { behaviours, categories } = this.props;
    return (
      <main className="container-fluid">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Withdrawal Request</h5>
            <p className="card-text" />
            <div className="form-group row">
              <div className="col-sm-4 mb-2">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="col-sm-4 mb-2">
                <input
                  type="numMP"
                  className="form-control"
                  placeholder="numMp"
                  value={numMp}
                  onChange={(e) => this.setState({ numMp: e.target.value })}
                />
              </div>
              <button
                className="btn btn-primary mb-2"
                disabled={!email && !numMp}
                onClick={() => this.handleSearch()}
              >
                Search
              </button>
            </div>
            <div className="form-group row">
              <div className="col-sm-4 mb-2">
                <select
                  className="custom-select"
                  value={behaviour}
                  onChange={(e) => this.setState({ behaviour: e.target.value })}
                >
                  <option defaultValue>Select behaviours</option>
                  {behaviours.map((behaviourItem) => (
                    <option key={behaviourItem} value={behaviourItem}>
                      {behaviourItem}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4 mb-2">
                <select
                  className="custom-select"
                  value={category}
                  onChange={(e) => this.setState({ category: e.target.value })}
                >
                  <option defaultValue>Select category</option>
                  {categories.map((categoryItem) => (
                    <option key={categoryItem} value={categoryItem}>
                      {categoryItem}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4 mb-2">
                <input
                  type="text"
                  className="form-control"
                  aria-label="description"
                  placeholder="description"
                  aria-describedby="inputGroup-sizing-default"
                  value={description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-2 mb-2">
                <input
                  required
                  type="number"
                  className="form-control"
                  placeholder="amount"
                  value={amount}
                  aria-describedby="inputGroup-sizing-default"
                  onChange={(e) => this.setState({ amount: e.target.value })}
                />
              </div>
              <div className="col-sm-4 mb-2">
                <select
                  className="custom-select"
                  value={employeeId}
                  onChange={(e) =>
                    this.setState({ employeeId: e.target.value })
                  }
                >
                  <option defaultValue>Select id</option>
                  {employeeIds.map((id) => (
                    <option key={id} value={id}>
                      {id}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-4 mb-2">
                <input
                  type="password"
                  className="form-control"
                  aria-label="Password"
                  placeholder="Password"
                  aria-describedby="inputGroup-sizing-default"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <div className="p-4">
                <button
                  className="btn btn-primary mb-2"
                  disabled={!behaviour || !category || !employeeId || !password}
                  onClick={() => this.handleWithdrawal()}
                >
                  Execute
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default WithdrawalSection;
