import React from "react";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import { Grid, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { v4 } from "uuid";
import useStyles from "./styles";
import CobeeButton from "../../../app/components/CobeeButton";

export default function ContentWrapper({
  children,
  title,
  options,
  previousURL,
}) {
  const {
    container,
    content,
    bar,
    block,
    backButton,
    barContainer,
    optionButton,
  } = useStyles();

  return (
    <Grid container>
      {children && (
        <Grid container className={container}>
          <Grid item xs={12} container className={content}>
            <Grid item xs={12} container className={block}>
              <Grid
                item
                xs={12}
                className={bar}
                container
                justify="space-between"
              >
                <div className={barContainer}>
                  {previousURL ? (
                    <CobeeButton
                      variant="unContained"
                      className={backButton}
                      onClick={() => window.location.replace(previousURL)}
                    >
                      <ChevronLeftIcon />
                      Back
                    </CobeeButton>
                  ) : null}
                  {title && <Typography variant="body1">{title}</Typography>}
                </div>
                <div className={barContainer}>
                  {options?.map(({ label, icon, action }) => (
                    <CobeeButton
                      key={v4()}
                      variant="unContained"
                      onClick={action}
                      className={optionButton}
                    >
                      {icon}
                      {label}
                    </CobeeButton>
                  ))}
                </div>
              </Grid>
              {children}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  options: PropTypes.array,
  previousURL: PropTypes.string,
};

ContentWrapper.defaultProps = {
  title: "",
  options: [],
  previousURL: "/",
};
